<template>
  <div class="register">
    <div class="main">
      <el-card class="main_shadow" shadow="always">
        <div class="register-left">
          <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="个人用户注册" name="one">
                 <div class="left-form">
            <el-form :label-position="labelPosition" label-width="110px" :model="user" ref="user1" :rules="dataRule">
              <el-form-item label="联系人姓名" prop="membername">
                <el-input placeholder="请输入姓名" v-model="user.membername"></el-input>
              </el-form-item>
              <el-form-item label="身份证号" prop="membercode">
                <el-input placeholder="请输入身份证号" v-model="user.membercode"></el-input>
              </el-form-item>
              <el-form-item label="手机号" prop="tel">
                <el-input placeholder="请输入手机号码" v-model="user.tel"></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="check">
                <div style="flex:1;">
                    <el-input placeholder="请输入短信验证码" v-model="user.check" style="width:55%;margin-right:10px;"></el-input>
                    <el-button slot="append" v-if="disabledgoCaptcha"
                    @click="goCaptcha" style="width:40%;text-align:center">{{btngoCaptcha}}</el-button>
                    <el-button slot="append" v-if="!disabledgoCaptcha"
                     style="width:40%;text-align:center">{{timegoCaptcha}}后重试</el-button>
                </div>
              </el-form-item>

              <el-form-item label="密码" prop="password">
                <el-input type="password" placeholder="请输入密码" v-model="user.password"></el-input>
              </el-form-item>
              <el-form-item label="重复密码" prop="repassword">
                <el-input type="password" placeholder="请再次输入密码" v-model="user.repassword"></el-input>
              </el-form-item>
              <el-form-item prop="captcha" label="验证码" >
                  <el-row :gutter="4">
                    <el-col :span="8">
                      <el-input v-model="user.captcha" placeholder="验证码">
                      </el-input>
                    </el-col>
                    <el-col :span="12" class="login-captcha">
                      <img :src="captchaPath" @click="getCaptcha()" alt="">
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item prop="checkedpt1" label="" style="margin-left: -110px;" >
                    <el-checkbox v-model="user.checkedpt1">我已阅读并同意
                    </el-checkbox>
                    <span style="color:#409eff;display:inline-block" @click="dialogVisibleYinsi1 =true">《平台服务协议》</span>
                 </el-form-item>
                <el-form-item prop="checkedpt2" label=""  style="margin-left: -110px;" >
                    <el-checkbox v-model="user.checkedpt2">我已阅读并同意
                    </el-checkbox>
                    <span style="color:#409eff" @click="dialogVisibleYinsi2 =true">《隐私政策》</span>
                </el-form-item>
              <el-button type="danger" class="form-btn" @click="regist('user1')">免费注册</el-button>
            </el-form>
                  </div>
              </el-tab-pane>
              <el-tab-pane label="企业用户注册" name="two">
                  <div class="left-form">
            <el-form :label-position="labelPosition" label-width="110px" :model="user" ref="user2" :rules="dataRule">
              <el-form-item label="企业名称" prop="enterprisename">
                <el-input placeholder="请输入企业名称" v-model="user.enterprisename"></el-input>
              </el-form-item>
              <el-form-item label="统一信用代码" prop="enterprisecode">
                <el-input placeholder="请输入社会统一信用代码" v-model="user.enterprisecode"></el-input>
              </el-form-item>
              <!-- <el-form-item label="法人姓名" prop="membername">
                <el-input placeholder="请输入法人姓名" v-model="user.membername"></el-input>
              </el-form-item>
              <el-form-item label="法人身份证号" prop="membercode">
                <el-input placeholder="请输入法人身份证号" v-model="user.membercode"></el-input>
              </el-form-item> -->
              <el-form-item label="手机号" prop="tel">
                <el-input placeholder="请输入手机号码" v-model="user.tel"></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="check">
                <div style="flex:1;">
                    <el-input placeholder="请输入短信验证码" v-model="user.check" style="width:55%;margin-right:10px;"></el-input>
                    <el-button slot="append" v-if="disabledgoCaptcha"
                    @click="goCaptcha" style="width:40%;text-align:center">{{btngoCaptcha}}</el-button>
                    <el-button slot="append" v-if="!disabledgoCaptcha"
                     style="width:40%;text-align:center">{{timegoCaptcha}}后重试</el-button>
                </div>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input type="password" placeholder="请输入密码" v-model="user.password"></el-input>
              </el-form-item>
              <el-form-item label="重复密码" prop="repassword">
                <el-input type="password" placeholder="请再次输入密码" v-model="user.repassword"></el-input>
              </el-form-item>
              <el-form-item prop="captcha" label="验证码" >
                  <el-row :gutter="4">
                    <el-col :span="8">
                      <el-input v-model="user.captcha" placeholder="验证码">
                      </el-input>
                    </el-col>
                    <el-col :span="12" class="login-captcha">
                      <img :src="captchaPath" @click="getCaptcha()" alt="">
                    </el-col>
                  </el-row>
                </el-form-item>
                 <el-form-item prop="checkedpt1" label="" style="margin-left: -110px;" >
                    <el-checkbox v-model="user.checkedpt1">我已阅读并同意
                    </el-checkbox>
                    <span style="color:#409eff;display:inline-block" @click="dialogVisibleYinsi1 =true">《平台服务协议》</span>
                 </el-form-item>
                <el-form-item prop="checkedpt2" label=""  style="margin-left: -110px;" >
                    <el-checkbox v-model="user.checkedpt2">我已阅读并同意
                    </el-checkbox>
                    <span style="color:#409eff" @click="dialogVisibleYinsi2 =true">《隐私政策》</span>
                </el-form-item>
              <el-button type="danger" class="form-btn" @click="regist2('user2')">免费注册</el-button>
            </el-form>
                  </div>
              </el-tab-pane>
          </el-tabs>
        </div>
        <div class="register-right" align="center">
          <div class="">
            <svg-icon name="warning" style="color:#e34545; width:50px;height:50px;"></svg-icon>
          </div>
          <div class="right-already">
            <span>已有账号？</span>
            <router-link to="/login">登录</router-link>
          </div>
        </div>
      </el-card>
    </div>
    <!-- <el-dialog
      title="滑动验证"
      :visible.sync="dialogVisiblecaptcha"
      width="24%"
      @opened="opencaptcha"
    >
      <div id="captcha" class="nc-container"></div>
    </el-dialog> -->
    <!--  《平台服务协议》  -->
    <el-dialog
        title="《平台服务协议》"
        :visible.sync="dialogVisibleYinsi1"
        width="30%"
    >
          <div class="Qiyue"  >
            <p class="textAlign fontBold">中国资产交易网平台服务协议</p>
            <div class="articles">
              <h3>提示条款</h3>
              <p >欢迎阅读、签署《中国资产交易网平台服务协议》（下称"本协议"）并使用"中国资产交易网"平台服务！
              各服务条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。
              </p>
              <p >
                <span class="fontweight">【审慎阅读】</span>
              <span>您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。</span>
              <span class=" fontweight unline">请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。</span>
              <span>如您对协议有任何疑问，可向平台客服咨询。</span>
              </p>
              <p >
                <span class="fontweight">【签约动作】</span>
              <span>当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与本平台达成一致，成为本平台“用户”。</span>
              <span class=" fontweight unline">阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。</span>
              </p>
              <p  >1定义</p>
              <p >【本公司】指中煤时代资产经营管理有限公司，是经营中国资产交易网平台的主体。</p>
              <p >【本平台】指基于互联网搭建的网上综合交易平台，即中国资产交易网（http://www.cnassets.com/）。</p>
              <p >【服务】本公司基于本平台向您提供产权交易的各项服务。</p>
              <p class="fontweight">交易规则包含现有规则后续变更及新发布的规则性文件。</p>
              <p >【同一用户】使用同一身份认证信息或经本平台排查认定多个账户的实际控制人为同一人的，均视为同一用户。</p>
              <p >
                <span>【补充协议】由于互联网高速发展，您与本平台经营者签署的本协议列明的条款并不能完整罗列并覆盖您和本平台的所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。</span>
                <span class="fontweight">本平台发布的任何新的规则及其他规则性文件均作为本协议的补充，与本协议不可分割且具有同等法律效力。您如果不同意补充协议，应立即停用本平台服务。您仍继续使用本平台服务的，即代表您已阅读、了解并同意接受补充协议。</span>
              </p>
              <p >2签约主体</p>
              <p >【平等主体】本协议由您与本平台经营者共同缔结，本协议对您与本平台经营者均具有合同效力。</p>
              <p >【主体信息】本平台经营者是指中煤时代资产经营管理有限公司，是经营本平台的法律主体。本协议项下，本平台经营者可能根据本平台的业务调整而发生变更，变更后的平台经营者与您履行本协议并向您提供服务，本平台经营者的变更不会影响您在本协议项下的权益。本平台经营者还有可能因为提供新的服务而新增，如您使用新增经营者服务的，视为您同意新增的平台经营者与您履行本协议。发生争议时，您应当根据您具体使用的服务及对您权益产生影响的具体行为对象确定与您履约的主体及争议相对方。</p>
              <p >3用户注册</p>
              <p >
                <span>3.1【用户资格】您确认，在您开始注册程序使用服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。</span>
                <span class="fontweight">若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。</span>
              </p>
              <p>3.2账户说明</p>
<p>【账户获得】当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，您可获得本平台账户并成为本平台用户。</p>
<p>【账户使用】您有权使用您设置或确认的用户名、邮箱、手机号码（以下简称"账户名称"）及您设置的密码（账户名称及密码合称"账户"）登录本平台。</p>
<p>【实名认证】在本平台进行交易活动的交易各方身份信息需在本平台进行登记备案，同时此备案信息也作为交易完成后进行交割时的凭证之一，为了使您能够完整地使用本平台的交易服务，请您在进行报名参与交易前完成实名认证。</p>
<p>3.3注册信息管理</p>
<p>
<span>【信息真实】在使用服务时，您应当按本平台页面的提示准确完整地提供您的信息（包括您的实名认证信息及电子邮件地址、联系电话、联系地址等）。</span><span class="fontweight">您了解并同意，您有义务保持您提供信息的真实性及有效性。</span></p>
<p class="fontweight">【用户名的合法性】您设置的本平台的用户名不得违反国家法律法规关于用户名的管理规定，否则本平台可回收您的用户名。用户名的回收不影响您以邮箱、手机号码登录本平台并使用服务。</p>
<p >3.4账户安全规范</p>
<p >【账户安全保管义务】您的账户为您自行设置并由您保管，本平台任何时候均不会主动要求您提供您的账户。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开本平台。</p>
<p class="fontweight">账户因您主动泄露或遭受他人攻击、诈骗等行为导致的损失及后果，均由您自行承担。</p>
<p class="fontweight">【账户行为责任自负】您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、交纳交易保证金、购买资产及披露信息等）负责。</p>
<p>【日常维护须知】如发现任何未经授权使用您账户登录本平台或其他可能导致您账户遭窃、遗失的情况，建议您立即通知本平台。</p><p class="fontweight">您理解本平台对您的任何请求采取行动均需要合理时间，在采取行动前已经产生的后果，本平台不承担任何责任。</p>
<p>4平台服务及规范</p>
<p>4.1资产的竞价与购买</p>
<p>【资产的购买】当您在本平台购买资产时，请您务必仔细确认所购商品的品名、价格、数量、型号、规格、尺寸等重要事项，并按照要求交纳交易保证金以获取竞价资格。</p>
<p><span class="fontweight">您的购买行为应当基于真实的需求，不得存在对资产实施恶意购买、恶意竞价等扰乱本平台正常交易秩序的行为。</span><span class="fontweight">基于维护本平台交易秩序及交易安全的需要，本平台发现上述情形时可主动关闭相关交易订单。</span></p>
<p>4.2交易争议处理</p>
<p>【交易争议处理途径】当您在本平台交易过程中，与交易相对方、其他交易相关方发生争议时，您可依据交易规则向本平台提出争议调解申请，由本平台经营者根据其所了解到的争议事实并依据交易规则进行调解。</p>
<p>4.3费用</p>
<p>【交易服务费】在本平台上进行的所有资产的交易均应遵守国家有关法律法规及本平台相关规定，具体的服务费收取标准在具体项目公告页面中明确公示。</p>
<p>4.4责任限制</p>
<p><span>【不可抗力】本平台依照法律规定履行基础保障义务，但</span><span class="fontweight">无法对由于信息网络设备维护、连接故障，电脑、通讯或其他系统的故障，电力故障，罢工，暴乱，火灾，洪水，风暴，爆炸，战争，政府行为，司法行政机关的命令或因第三方原因而给您造成的损害结果承担责任。</span></p>
<p class="fontweight">【交易风险】本平台上发布的信息系根据交易委托方提供的信息进行的发布，本平台对信息的审核仅判断其是否符合本平台交易规则等相关规定，不保证交易信息的真实性、完整性和有效性，不保证项目标的与实物相符，对此您应谨慎判断，并自行承担交易风险。</p>
<p>5用户信息的保护及授权</p>
<p>5.1个人信息的保护</p>
<p>本平台非常重视用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，在您使用本平台提供的服务时，您同意按照本平台上公布的隐私权政策收集、存储、使用、披露和保护您的个人信息。为了能够充分了解您个人信息的处理方式，建议您完整地阅读《隐私政策》，以帮助您更好地保护您的隐私权。</p>
<p>5.2非个人信息的保证</p>
<p><span>【信息的发布】您声明并保证，您对您所发布的信息拥有相应、合法的权利。否则，</span><span class="fontweight">本平台可对您发布的信息依法或依本协议进行删除或屏蔽。</span></p>
<p class="fontweight">【禁止性信息】您应当确保您所发布的信息不包含以下内容：</p>
<p class="fontweight">（一）违反国家法律法规禁止性规定的；</p>
<p class="fontweight">（二）政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
<p class="fontweight">（三）欺诈、虚假、不准确或存在误导性的；</p>
<p class="fontweight">（四）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；</p>
<p class="fontweight">（五）侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；</p>
<p class="fontweight">（六）存在可能破坏、篡改、删除、影响本平台任何系统正常运行或未经授权秘密获取本平台及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；</p>
<p class="fontweight">（七）其他违背社会公共利益或公共道德或依据本平台交易规则的规定不适合在本平台上发布的。</p>
<p>6、用户的违约及处理</p>
<p>6.1违约认定</p>
<p>发生如下情形之一的，视为您违约：</p>
<p>（一）使用服务时违反有关法律法规规定的；</p>
<p>（二）违反本协议或本协议补充协议约定的。</p>
<p>为适应电子商务发展和满足用户对高效优质服务的需求，您理解并同意，本平台可在交易规则中约定违约认定的程序和标准。您有义务对您的数据异常现象进行充分举证和合理解释，否则将被认定为违约。</p>
<p>6.2违约处理措施</p>
<p><span>【行为限制】您在本平台上实施的行为，或虽未在本平台上实施但对本平台及其用户产生影响的行为构成违约的，</span><span class="fontweight">本平台可依据相应规则对您执行限制参加本平台交易活动、中止向您提供部分或全部服务、划扣交易保证金等处理措施。</span></p>
<p>【处理结果公示】本平台可将对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息以适当的方式予以公示。</p>
<p>6.3赔偿责任</p>
<p class="fontweight">如您的行为使本平台及/或其关联公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿本平台及/或其关联公司的上述全部损失。</p>
<p class="fontweight">如您的行为使本平台及/或其关联公司遭受第三人主张权利，本平台及/或其关联公司可要求您直接向第三人承担责任或在对第三人承担金钱给付等义务后就全部损失向您追偿。</p>
<p class="fontweight">您同意本平台划扣相应款项支付上述赔偿款项时，如您在本平台指定指定资金结算账户中的款项不足以支付上述赔偿款项的，本平台可继续追偿。</p>
<p>7协议的变更</p>
<p>本平台可根据国家法律法规变化及维护交易秩序、保护消费者权益需要，适时修改本协议、补充协议，变更后的协议、补充协议（下称变更事项）将以本协议第8条约定的方式通知您。</p>
<p>如您不同意变更事项，您有权于变更事项确定的生效日前联系本平台反馈意见。如反馈意见得以采纳，本平台将酌情调整变更事项。</p>
<p class="fontweight">如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用服务，则视为您同意已生效的变更事项。</p>
<p>8通知</p>
<p>您同意本平台以以下合理的方式向您送达各类通知：</p>
<p>（一）本平台网站公示的信息；</p>
<p>（二）站内信、客户端推送的消息；</p>
<p>（三）根据您预留于本平台的联系方式发出的电子邮件、短信、函件等。</p>
<p>9协议的终止</p>
<p>9.1终止的情形</p>
<p>【用户发起的终止】当出现以下情况时，您可选择终止本协议：</p>
<p>（一）变更事项生效前您停止使用并明示不愿接受变更事项的；</p>
<p>（二）您明示不愿继续使用服务，且符合本平台终止条件的。</p>
<p>【本平台发起的终止】出现以下情况时，本平台可以本协议第8条的所列的方式通知您终止本协议：</p>
<p>（一）您违反本协议约定，本平台依据违约条款终止本协议的；</p>
<p>（二）您盗用他人账户、发布违禁信息、骗取他人财物、售假、扰乱市场秩序、采取不正当手段谋利等行为，本平台依据交易规则对您的账户予以停止使用的；</p>
<p>（三）除上述情形外，因您多次违反交易规则相关规定且情节严重，本平台依据交易规则对您的账户予以停止使用的；</p>
<p>（四）您的账户被本平台依据本协议回收的；</p>
<p>（五）其它应当终止服务的情况。</p>
<p>9.2协议终止后的处理</p>
<p class="fontweight">【用户信息披露】本协议终止后，除法律有明确规定外，本平台无义务向您或您指定的第三方披露您账户中的任何信息。</p>
<p>【本平台权利】本协议终止后，本平台仍享有下列权利：</p>
<p>（一）继续保存您留存于本平台的本协议第5条所列的各类信息；</p>
<p>（二）对于您过往的违约行为，本平台仍可依据本协议向您追究违约责任。</p>
<p>10法律适用与其他</p>
<p class="fontweight">【法律适用】本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。</p>
<p class="fontweight">【诉讼】因本协议或本平台服务所引起或与其有关的任何争议应向本平台经营者所在地有管辖权的法院提起诉讼，并以中华人民共和国法律为管辖法律。</p>

            </div>
            <!-- 平台协议结束 -->
          </div>
            <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleYinsi1 = false">取 消</el-button>
      </span>
    </el-dialog>
    <!--  隐私政策  -->
    <el-dialog
        title="《隐私政策》"
        :visible.sync="dialogVisibleYinsi2"
        width="30%"
    >
      <div class="Qiyue"  >
        <p class="textAlign fontBold">中国资产交易网隐私政策</p>
        <div class="articles">
          <h3>提示条款</h3>
          <p>本平台提醒您：在使用本平台各项服务前，请您务必仔细阅读并透彻理解本声明。如对本声明内容有任何疑问，您可向本平台客服咨询。阅读本声明的过程中，如果您不同意本声明或其中任何内容，您应立即停止使用本平台服务。如果您使用本平台服务，您的使用行为将被视为对本声明全部内容的认可。</p>
<p>定义</p>
<p>【本公司】指中煤时代资产经营管理有限公司，是经营中国资产交易网平台的主体。</p>
<p>【本平台】指基于互联网搭建的网上综合交易平台，即中国资产交易网（http://www.cnassets.com/）。</p>
<p >隐私权政策</p>
<p>本平台重视用户个人信息的保护，在您使用本平台提供的服务时，本平台将按照本隐私权政策收集、使用及共享您的个人信息。本隐私权政策包含了收集、存储、使用、共享和保护您的个人信息的条款，建议您完整地阅读本隐私权政策，以帮助您了解维护自己隐私权的方式。如您对本隐私权政策有任何疑问，您可以通过本平台公布的联系方式与我们联系。如果您不同意本隐私权政策任何内容，您应立即停止使用本平台服务。当您使用本平台提供的任一服务时，即表示您已同意我们按照本隐私权政策来合法使用和保护您的个人信息。</p>
<p>一、适用范围</p>
<p>本隐私权政策适用于本平台提供的所有服务，您访问本平台网站及/或登录相关客户端使用我们提供的服务，均适用本隐私权政策。</p>
<p>二、信息采集方式</p>
<p>1.您提供的信息</p>
<p>当您注册本账户及您在使用本平台提供的相关服务时填写及/或提交的信息，个人用户包括您的姓名、身份证号码、电话号码，企业用户包含企业的名称、统一社会信用代码、企业法人姓名、企业法人身份证号码、企业银行账户信息、企业联系人姓名、企业联系人身份证号码、电话号码。</p>
<p>2.在您使用服务过程中收集的信息</p>
<p>为了提供并优化您需要的服务，本平台会收集您使用服务的相关信息，这类信息包括：</p>
<p>在您使用本平台服务，或访问本平台网页时自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；如您下载或使用本客户端软件，或访问移动网页使用本平台服务时，本平台可能会读取与您位置和移动设备相关的信息，包括但不限于设备型号、设备识别码、操作系统、分辨率、电信运营商等。</p>
<p>除上述信息外，本平台还可能为了提供服务及改进服务质量的合理需要而收集您的其他信息，包括您与客户服务团队联系时您提供的相关信息，您参与问卷调查时发送的问卷答复信息以及与我们互动时发送的相关信息等。与此同时，为提高您使用本平台提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，本平台可能会通过了解一些您的网络使用习惯、您常用的软件信息等手段来判断您账户的风险，并可能会记录一些我们认为有风险的URL。</p>
<p>3.来自第三方的信息</p>
<p>为了给您提供更好、更优、更加个性化的服务，或共同为您提供服务，或为了预防互联网欺诈的目的，本平台会依据法律的规定或与您的约定或征得您同意的前提下，记录您的个人信息。</p>
<p>您了解并同意，以下信息不适用本隐私权政策：</p>
<p>a）您在使用本平台提供的搜索服务时输入的关键字信息；</p>
<p>b）信用评价、违反法律规定或违反本平台规则行为及已对您采取的措施；</p>
<p>c)应法律法规要求需公示的企业名称等相关工商注册信息以及自然人经营者的信息。</p>
<p>三、信息的使用</p>
<p>因收集您的信息是为了向您提供服务及提升服务质量的目的，为了实现这一目的，本平台会把您的信息用于下列用途：</p>
<p>1.向您提供您使用的各项服务，并维护、改进这些服务。</p>
<p>2.向您推荐您可能感兴趣的内容，包括但不限于向您发出产品和服务信息，或通过系统向您展示个性化的推广信息，或者在征得您同意的情况下向您发送与您关注商品或/及权益有关其产品和权益的信息。</p>
<p>3.本平台可能会将来自某项服务的个人信息与来自其他服务的信息结合起来，用于为了给您提供更加个性化的服务使用。</p>
<p>4.经您许可的其他用途。</p>
<p>四、信息的共享</p>
<p>本平台对您的信息承担保密义务，不会为满足营销目的而向第三方出售或出租您的任何信息。下列情况下才将您的信息与第三方共享：</p>
<p>1.事先获得您的同意或授权。</p>
<p>2.根据法律法规的规定或行政或司法机构的要求。</p>
<p>3.向资产委托方分享您的个人信息。</p>
<p>4.如您是知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷。</p>
<p>5.如您出现违反中国有关法律、法规或者本平台交易规则相关规定的情况，需要向第三方披露。</p>
<p>6.为维护本平台其他用户的合法权益。</p>
<p>五、Cookie和网络Beacon的使用</p>
<p>为使您获得更轻松的访问体验，您访问本平台相关网站或使用本平台提供的服务时，我们可能会通过小型数据文件识别您的身份，目的是为您省去重复输入注册信息的步骤，或帮助判断您的账户安全。这些数据文件可能是Cookie，FlashCookie，或您的浏览器或关联应用程序提供的其他本地存储（统称"Cookie"）。</p>
<p>请您理解，本平台的某些服务只能通过使用"Cookie"才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝Cookie，但这一举动在某些情况下可能会影响您安全访问本平台相关网站和使用我们提供的服务。</p>
<p>网页上常会包含一些电子图像（称为"单像素"GIF文件或"网络beacon"），使用网络beacon可以帮助网站计算浏览网页的用户或访问某些cookie，本平台会通过网络beacon收集您浏览网页活动的信息，例如您访问的页面地址、您先前访问的援引页面的位址、您停留在页面的时间、您的浏览环境以及显示设定等。</p>
<p>六、信息的存储</p>
<p>有关您的信息和资料将保存在本平台的服务器上，这些信息和资料可能传送至您所在国家、地区或收集信息和资料所在地并在该地被访问、存储和展示。</p>
<p>七、个人信息保护</p>
<p>为保障您的信息安全，本平台将采取多种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。同时，本平台对可能接触到您的信息的员工或外包人员采取了严格管理，包括但不限于控制权限、签署保密协议，监控操作情况等措施。</p>
<p>您的账户均有安全保护功能，请妥善保管您的账户及密码信息。本平台将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造，但对非本平台过失造成的信息安全问题不承担责任。</p>
<p>八、未成年人保护</p>
<p>本平台承诺不会在知情的情况下收集未成年人的个人信息，也不建议未成年人使用我们的服务。除非所在地法律允许且监护人同意，未成年人请勿自行注册账户或发送个人信息至本平台。如在不知情的情况下收集到未成年人的信息，本平台将在知情后尽快删除。如果您认为我们可能不当地持有来自于或关于未成年人的信息，请联系本平台客服。</p>

        </div>
      </div>
          <el-button @click="dialogVisibleYinsi2 = false" style="margin-top:20px">取 消</el-button>
    </el-dialog>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import { getUUID } from '@/utils'
export default {
  data() {
    var checkphone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else if (!this.isCellPhone(value)) {
        callback(new Error('请输入正确的手机号!'))
      } else {
        callback()
      }
    }
     var checkyinsi = (rule, value, callback) => {
      if (value ==false) {
        callback(new Error('请先勾选并同意《平台服务协议》和《隐私协议》'))
      } else {
        callback()
      }
    }
    var checkpass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.user.password) {
        callback(new Error('两次密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      dialogVisibleYinsi1: false,
      dialogVisibleYinsi2: false,
        // checkedpt1: false,
        // checkedpt2: false,
      activeName:'one',
      disabledgoCaptcha: true,
      timegoCaptcha: 30,
      interval: null,
      btngoCaptcha:"发送验证码",
      labelPosition: 'left',
      captchaPath: '',
      user: {
        checkedpt1: false,
        checkedpt2: false,
        membername: null, //姓名
        membercode: null, //身份证
        check: null, //短信验证码
        sex: 0, //性别
        tel: null, //电话
        enterprisename: '', //企业名称
        enterprisecode: '', //企业码
        password: "", //密码
        repassword: "",
        type: 4,
        captcha: '', //验证码
        isloading: 0,// 企业和个人
        uuid:'',

      },
      dataRule: {
        membername: [
          {required: true, message: '姓名不能为空', trigger: 'blur'}
        ], //姓名
         check: [
          {required: true, message: '短信验证码不能为空', trigger: 'blur'}
        ],
        sex: [
          {required: true, message: '性别不能为空', trigger: 'blur'}
        ], //性别
        tel: [
          {required: true, validator: checkphone, trigger: 'blur'}
        ],
        membercode: [
          {required: true, message: '身份证不能为空', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur'}
        ],
        repassword: [
          {required: true, validator: checkpass, trigger: 'blur'}
        ],
        captcha: [
          { required: true, message: '验证码不能为空', trigger: 'blur' }
        ],
        enterprisename: [
          { required: true, message: '企业名称不能为空', trigger: 'blur' }
        ],
        enterprisecode: [
          { required: true, message: '社会统一信用代码不能为空', trigger: 'blur' }
        ],
        checkedpt1: [
          { required: true, validator: checkyinsi, message: '请先阅读并同意《平台服务协议》', trigger: 'blur' }
        ],
        checkedpt2: [
          { required: true, validator: checkyinsi, message: '请先阅读并同意《隐私协议》', trigger: 'blur' }
        ],
      },
      checkedContract: false,//契约锁服务协议
      checkedHide: false,//隐私协议
      checkedNumber: false,//数字证书隐私协议
      activeNames: ['1'],
      checked: true,
      dialogVisibleQiyue: false,//契约弹出框
      dialogVisibleYinsi: false,//隐私弹出框
      dialogVisibleZhengshu: false,//证书弹出框
      disQiyue: false,//契约弹出框里面30s显示的
      disYinsi: false,//隐私弹出框里面30s显示的
      disZhengshu: false,//证书弹出框里面30s显示的
      checkedDisQiyue: false,//契约弹出框里面30s显示的勾选按钮
      checkedDisYinsi: false,//契约弹出框里面30s显示的勾选按钮
      checkedDisZhengshu: false,//契约弹出框里面30s显示的勾选按钮
      timerContract: '',//契约定时器
      timerAgreement: '',//隐私协议定时器
      timerCertificate: '',//数字证书定时器
      captcha:false,
      dialogVisiblecaptcha:false,//滑动验证
    }
  },
  created () {
        if(localStorage.getItem('timecount')==null){
          localStorage.setItem('timecount', 60)
        }
      this.getCaptcha()
    },
  mounted() {
  },
  methods: {
    ...mapActions(["setUser", "setToken","setShowLogin", "setShoppingCart"]),
        handleClick(e){
      if(e.name =='one'){
      this.user.isloading = 0
      }else{
      this.user.isloading = 1
      }
    },
    getCaptcha () {
      this.user.uuid = getUUID()
      this.captchaPath = this.$target +`auth/captcha?uuid=${this.user.uuid}`
    },
    opencaptcha(){
	//里边放阿里云滑动验证js代码
    var nc_token = ["FFFF0N0000000000A4D6", (new Date()).getTime(), Math.random()].join(':');
    var NC_Opt =
     {
         renderTo: "#captcha",
         appkey: "FFFF0N0000000000A4D6",
         scene: "nc_other",
         token: nc_token,
         customWidth: 300,
         trans:{"key1":"code0"},
         elementID: ["usernameID"],
         is_Opt: 0,
         language: "cn",
         isEnabled: true,
         timeout: 3000,
         times:5,
         apimap: {
         },
         callback: function () {
            this.dialogVisiblecaptcha = false;
         }
     }
     var nc = new noCaptcha(NC_Opt)
     nc.upLang('cn', {
         _startTEXT: "请按住滑块，拖动到最右边",
         _yesTEXT: "验证通过",
         _error300: "哎呀，出错了，点击<a href=\"javascript:__nc.reset()\">刷新</a>再来一次",
         _errorNetwork: "网络不给力，请<a href=\"javascript:__nc.reset()\">点击刷新</a>",
     })
},
    handleChange(val) {
    },
    //点击验证显示弹出框事件
    goCaptcha() {
      // this.disabledgoCaptcha = false
      // this.dialogVisiblecaptcha = true; // 滑动验证
          if(this.user.tel == null || this.user.tel ==''){
            this.$message({
                    message: '请输入手机号',
                    type: 'error',
                    showClose: 'true'
                  })
            return false
          }
          if (!this.interval) {
                          this.$post('/shoppingui/alimessage2',this.user).then((data) => {
              if (data.code == '0') {
                if (data.code == '0') {
                  // this.$router.push({path: "/"});
                } else {
                  this.$message({
                    message: data.message,
                    type: 'error',
                    showClose: 'true'
                  })
                }
              } else {
                this.$message({
                  message: data.msg,
                  type: 'error',
                  showClose: 'true'
                })
              }
            })
            this.disabledgoCaptcha = false;
            this.interval = setInterval(() => {
              if (this.timegoCaptcha > 0 && this.timegoCaptcha <= 30) {
                this.timegoCaptcha--;
              } else {
                this.timegoCaptcha= 60
                this.disabledgoCaptcha = true;
                clearInterval(this.interval);
                this.interval = null;
              }
            }, 1000);
          }else{
            // this.disabledgoCaptcha = true;
          }
    },

    //点击契约锁协议显示弹出框事件
    goContract() {
      this.dialogVisibleQiyue = true;
      this.timerContract = setInterval(() => {
        this.disQiyue = true;
      }, 30000);
    },
    //点击隐私政策显示弹出框事件
    goAgreement() {
      this.dialogVisibleYinsi = true;
      this.timerAgreement = setInterval(() => {
        this.disYinsi = true;
      }, 30000);
    },
    godialogVisibleYinsi1(){
        this.dialogVisibleYinsi1 = true;
    },
    godialogVisibleYinsi2(){
        this.dialogVisibleYinsi2 = true;
    },
    //点击数字证书隐私协议显示弹出框事件
    goCertificate() {
      this.dialogVisibleZhengshu = true;
      this.timerCertificate = setInterval(() => {
        this.disZhengshu = true;
      }, 30000);
    },
    //契约锁协议滚动条
    listScrollQiyue($event) {
      let _this = this;
      if ((parseInt($event.target.clientHeight) + parseInt($event.target.scrollTop)) === parseInt($event.target.scrollHeight) && _this.checkedDisQiyue == true) {
        _this.checkedContract = true;
      }
    },
    //隐私政策滚动条
    listScrollYinsi($event) {
      let _this = this;
      if ((parseInt($event.target.clientHeight) + parseInt($event.target.scrollTop)) === parseInt($event.target.scrollHeight) && _this.checkedDisYinsi == true) {
        _this.checkedHide = true;
      }
    },
    //数字证书隐私协议滚动条
    listScrollZhengshu($event) {
      let _this = this;
      if ((parseInt($event.target.clientHeight) + parseInt($event.target.scrollTop)) === parseInt($event.target.scrollHeight) && _this.checkedDisZhengshu == true) {
        _this.checkedNumber = true;
      }
    },
    changeQiyue(val) {
      this.checkedDisQiyue = val;
    },
    changeYinsi(val) {
      this.checkedDisYinsi = val;
    },
    changeZhengshu(val) {
      this.checkedDisZhengshu = val;
    },
    regist(user1) {
      this.user.sex = 0
      this.user.enterprisename = '个人', // 企业名称
      this.user.enterprisecode= '0', // 企业码
      this.user.isloading = 0,// 企业和个人
        this.$refs[user1].validate((valid) => {
          if (valid) {
           this.$post('/shoppingui/aregister/memberregister',  this.user).then((data) => {
                if (data.code == '200') {
                  this.$message({
                    duration: 3000,
                    message: '注册成功请登录',
                    type: 'success',
                    showClose: 'true'
                  })
                  this.$router.push({path: "/login"});
                } else {
                  this.$message({
                    duration: 3000,
                    message: data.message,
                    type: 'error',
                    showClose: 'true'
                  })
                }
            })
          }
        });
    },
    regist2(user2) {
      this.user.sex = 0
      this.user.membername=this.user.enterprisename
      this.user.membercode=this.user.enterprisecode
      this.user.isloading = 1,// 企业和个人
        this.$refs[user2].validate((valid) => {
          if (valid) {
           this.$post('/shoppingui/aregister/memberregister',  this.user).then((data) => {
                if (data.code == '200') {
                  this.$message({
                    duration: 3000,
                    message: '注册成功请登录',
                    type: 'success',
                    showClose: 'true'
                  })
                  this.$router.push({path: "/login"});
                } else {
                    console.log(data)
                  this.$message({
                    duration: 3000,
                    message: data.message,
                    type: 'error',
                    showClose: 'true'
                  })
                }
            })
          }
        });
    },
    // 检查手机号
    isCellPhone(val) {
      if (!/^1(3|4|5|6|7|8)\d{9}$/.test(val)) {
        return false
      } else {
        return true
      }
    }
  },
  distoryed() {
    clearInterval(this.timerContract);
    clearInterval(this.timerAgreement);
    clearInterval(this.timerCertificate);
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/index.css";
@import "../assets/css/page/register.scss";
/deep/.el-tabs {
  margin-left: 2%;
  user-select: none;
}
/deep/.el-tabs .el-tabs__item{
  font-size:22px !important;
}
/deep/.el-checkbox__inner {
    border: 1px solid #5f5b5b;
}
.articles{
  text-indent: 2em;
  padding: 0 30px 0 30px;
}
.fontweight{
  font-weight: 700;
    text-decoration: underline;
}
.unline{
  text-decoration: underline;
}
</style>
